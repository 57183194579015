.left_menu {
  display: flex;
  align-items: center;
  .logo_wrap {
    img {
      height: 40px;
    }
  }
  .menu_wrap {
    padding-left: 25px;
    .navbar {
      padding: 0;
      ul {
        display: flex;
        align-items: center;
        li {
          padding: 10px 15px;
          &.mobile_show {
            display: none;
          }
          &:last-child {
            padding-left: 0;
          }
          &.has-children {
            position: relative;
            .sub-menu {
              position: absolute;
              left: 15px;
              top: 45px;
              background: $bgWhite;
              border: 1px solid $primary;
              border-radius: 7px;
              opacity: 0;
              visibility: hidden;
              li {
                padding: 0;
                a {
                  padding: 4px 15px;
                  display: inline-block;
                  color: $primary;
                }
              }
            }
            &:hover {
              .sub-menu {
                opacity: 1;
                visibility: visible;

                &:hover {
                  background: $primary;
                  li {
                    a {
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
          a {
            color: $textLight;
            font-weight: 600;
            &.btn_border {
              color: $primary;
              &:hover {
                color: $bgWhite;
              }
            }
            &.btn_primary {
              color: $bgWhite;
            }
            img {
              filter: grayscale(1) invert(1);
            }
            &:hover {
              color: $primary;
              img {
                filter: inherit;
              }
            }
            &.active {
              color: $primary;
              img {
                filter: inherit;
              }
            }
          }
          button.MuiButtonBase-root {
            padding: 0;
            color: $textLight;
            text-transform: capitalize;
            font-size: 14px;
            font-weight: 400;
          }
          .dropdown {
            .dropdown-toggle {
              padding: 0;
              &:after {
                display: none;
              }
            }
            &.active {
              .dropdown-toggle {
                color: $primary;
              }
            }
            .dropdown-menu {
              width: 845px;
              top: 33px !important;
              ul.report_dropdown {
                display: block;
                li {
                  padding: 0;
                  a {
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 18px;
                    padding: 6px 10px;
                    display: block;
                    color: $textDark;
                    &:hover,
                    &.active {
                      color: #454545;
                      background: #feedf1;
                    }
                  }
                }
              }
              .dropdown-item {
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
                padding: 6px 10px;
                color: $textDark;
                &:hover {
                  color: #454545;
                  background: #feedf1;
                }
                &.active {
                  color: $primary;
                  background: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
}

.search_wrap {
  position: relative;
  .form-control {
    width: 220px;
    background: transparent;
    border: 1px solid $textDark;
    border-radius: 5px;
    box-shadow: none;
    font-weight: 400;
    font-size: 13px;
    color: $bgWhite;
  }
  .btn_primary.small {
    position: absolute;
    right: 1px;
    top: 1px;
    padding: 2px 5px;
    border-radius: 0px 5px 5px 0px;
  }
}

.right_menu > ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > li {
    padding: 0 15px;
    color: $bgWhite;
    span {
      color: $primary;
    }
    > a {
      color: $textLight;
      font-weight: 600;
      &:hover,
      &.active {
        color: $primary;
        img.lock_img {
          filter: grayscale(0);
        }
      }
      img.lock_img {
        filter: grayscale(1) brightness(1.4);
      }
    }
  }
}

.dropdown-menu li {
  position: relative;
}
.dropdown-menu .submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: 0 !important;
}
.dropdown-menu .submenu-left {
  right: 100%;
  left: auto;
}

.dropdown-menu > li:hover {
  background-color: #f1f1f1;
}
.dropdown-menu > li:hover > .submenu {
  display: block;
}

.right_menu .dropdown-toggle:after {
  display: none;
}
.dropdown-menu {
  background: #ffffff;
  border: 1px solid #dadddf;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 0;
  top: 11px !important;
  &.notification_menu {
    top: 23px !important;
    right: 0px;
    width: 500px;
    height: 600px;
    overflow: auto;
    p {
      text-wrap: wrap;
    }
    .dropdown-item {
      border-bottom: 1px solid #43434336;
      .notification-items {
        .notification-title {
          color: $secondary;
          font-size: 18px;
        }
        .notification-data {
          padding: 0px 10px;
          .notification-details {
            padding: 0px 5px;
            span {
              color: $primary;
            }
          }
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
    .notification_button {
      span {
        padding: 5px 10px;
        margin: 5px;
        border-radius: 5px;
        font-size: 14px;
        &:first-child {
          border: 1px solid $primary;
          background-color: $primary;
          color: $textWhite;
          transition: all 0.5 ease;
          &:hover {
            background-color: $bgWhite;
            border: 1px solid $primary;
            color: $primary;
            transition: all 0.5 ease;
          }
        }
        &:last-child {
          border: 1px solid $primary;
          color: $primary;
          transition: all 0.5 ease;
          &:hover {
            border: 1px solid $primary;
            background-color: $primary;
            color: $textWhite;
            transition: all 0.5 ease;
          }
        }
      }
    }
  }
}

.dropdown-menu li .dropdown-item {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  padding: 6px 10px;
}
.dropdown-menu li .dropdown-item:hover,
.dropdown-menu li .dropdown-item.active {
  background: $bgDangerLight;
  color: $textDark;
}

.megamenu_wrap .MuiPaper-root {
  transform: translate(0, 12px) !important;
  max-height: inherit !important;
}

.megamenu_wrap ul.MuiList-root .row .col {
  border-right: 1px solid $borderColor;
}
.menu_wrap.mobile_menu_wrap {
  display: none;
}
.overlay_wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: -1;
  transition: 0.5s ease;
}

@media (max-width: 1700px) {
  .left_menu .menu_wrap .navbar ul > li {
    padding: 10px;
  }
}
@media (max-width: 1600px) {
  .left_menu .menu_wrap .navbar ul > li {
    padding: 10px 8px;
  }
  .right_menu > ul > li:first-child {
    display: none;
  }
}

@media (max-width: 1520px) {
  .left_menu .menu_wrap .navbar ul > li a,
  .left_menu .menu_wrap .navbar ul > li button.MuiButtonBase-root {
    font-size: 13px;
  }
}

@media (max-width: 1461px) {
  .left_menu .menu_wrap {
    padding-left: 10px;
  }
  .right_menu > ul > li {
    padding: 0 10px;
  }
  .left_menu .menu_wrap .navbar ul > li a,
  .left_menu .menu_wrap .navbar ul > li button.MuiButtonBase-root {
    font-size: 11px;
  }
  .left_menu .menu_wrap .navbar ul > li a img {
    width: 18px;
  }
}

@media (max-width: 1399px) {
  // .menu_wrap {
  //   display: none;
  // }
  // .menu_wrap.mobile_menu_wrap {
  //   display: block;
  // }
  .left_menu {
    padding: 8px 0;
    .menu_wrap {
      .navbar {
        .navbar-collapse {
          position: fixed;
          top: 50px;
          left: -300px;
          width: 300px;
          background: $secondary;
          height: 100vh !important;
          padding: 20px;
          display: block;
          transition: 0.5s ease;
          &.show {
            left: 0;
          }
          ul {
            display: block;
            li {
              border-top: 1px solid #404040;
              padding: 10px 0;
              &.has-children {
                ul.sub-menu {
                  display: none;
                }
              }
              .dropdown {
                .dropdown-menu {
                  background: transparent;
                  border: 0;
                  padding: 0;
                  padding-left: 10px;
                  max-height: 280px;
                  overflow-y: auto;
                  width: 100%;
                  overflow-x: hidden;
                  .dropdown-item {
                    font-size: 13px;
                    padding: 7px 0px;
                    border: 0;
                    color: $textLight;
                    &:hover,
                    &:focus,
                    &.active {
                      background: transparent;
                      color: $primary;
                    }
                  }
                }
              }
              a {
                font-size: 14px;
                padding: 0;
                display: block;
                &.btn_border,
                &.btn_primary {
                  padding: 5px 12px;
                  font-size: 13px;
                  line-height: 18px;
                  border-radius: 5px;
                  text-align: center;
                }
              }
              &.mobile_show {
                display: block;
              }
            }
          }
        }
        .navbar-toggler {
          border: 0;
          padding: 0;
          margin-left: 20px;
          box-shadow: none;
          outline: 0;
        }
        .navbar-toggler-icon {
          background: $bgWhite;
          width: 25px;
          height: 2px;
          border-radius: 5px;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: -8px;
            width: 18px;
            height: 2px;
            border-radius: 5px;
            background: $bgWhite;
          }
          &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -8px;
            width: 14px;
            height: 2px;
            border-radius: 5px;
            background: $bgWhite;
          }
        }
      }
    }
  }
  .navbar-nav .nav-link.active,
  .navbar-nav .show > .nav-link {
    color: $textLight;
  }
}
.count_number {
  position: relative;
  font-size: 8px;
  top: -5px;
  border-radius: 50px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 7px;
  z-index: 9;
  width: 18px;
  background-color: $bgWhite;
  height: 16px;
  &::before {
    content: '';
    position: absolute;
    height: 20px;
    z-index: -1;
  }
}

.notification_gray {
  filter: grayscale(1) brightness(1.4);
}
